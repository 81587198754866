import { useEffect } from "react";

const MagniteAnalytics = () => {
  useEffect(() => {
    const loadMagniteScript = () => {

      if (!window.CustomerConnectAnalytics) {
        const script = document.createElement("script");
        script.innerHTML = `!function(a,l,b,c,k,s,t,g,A){a.CustomerConnectAnalytics=k,a[k]=a[k]||function(){

          (a[k].q=a[k].q||[]).push(arguments)},g=l.createElement(b),A=l.getElementsByTagName(b)[0],

          g.type="text/javascript",g.async=!0,g.src=c+"?id="+s+"&parentId="+t,A.parentNode.insertBefore(g,A)

        }(window,document,"script","//pb-script-03.ccgateway.net/script","cca",window.location.hostname,"ef0017cfcf");`

        document.body.appendChild(script);
      }
    };

    // Load script after a slight delay to prevent blocking render
    const timeout = setTimeout(() => {
      loadMagniteScript();
    }, 1500); // 1 second delay

    return () => clearTimeout(timeout);
  }, []);

  return null;
};

export default MagniteAnalytics;
